/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #01070a;
} */
.imgRocket{
    height: 20vh;
    width: 39;
}
.scene{
    
    
    background-color: #01070a;
    position: relative;
    width: 100%;
    height: 50vh;
    /* background:#000; */
    
    
    

}
.scene i{
    position: absolute;
    top: -250px;
    background: rgba(255,255,255,0.5);
    animation: animateStars linear infinite;
}
@keyframes animateStars {
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(100vh);
    }
    
}
.scene .roket{
    position: relative;
    animation: animate 0.2s ease infinite;
} 
@keyframes animate
{
    0%,100%{
        transform: translateY(-3px);
    }
    50%{
        transform: translateY(3px);
    }
   
}